import Head from 'next/head';
import { useRouter } from 'next/router';
import '../../styles/globals.css';
import 'reactjs-popup/dist/index.css';
import 'swiper/css';
import 'swiper/css/pagination';

import Header from '../components/Header';
import Footer from '../components/Footer';
import { LoadingOverlay } from '../components/LoadingOverlay';
import { NewsDataProvider } from '../components/NewsData';
import { HomePopup } from '../components/HomePopup';
import { TranslationProvider } from '../components/TranslationContext';
import { useEffect, useState } from 'react';
import { VisibleProvider } from '../components/VisibleContext';

function MyApp({ Component, pageProps, pageTitle }) {
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();
  const canonicalUrl = `https://azeronline.com${router.asPath}`;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsLoading(false);
    }
  }, []);

  return (
    <VisibleProvider>
      <TranslationProvider {...pageProps}>
        <NewsDataProvider>
          <Head>
            <title>{pageTitle}</title>
            <link rel="icon" href="/assets/fav.png" />
            <meta name="author" content="Ilgar Guliyev" />
            <link rel="canonical" href={canonicalUrl} />
          </Head>
          <Header {...pageProps} />
          <Component {...pageProps} />
          <Footer {...pageProps} />
          {isLoading ? <LoadingOverlay /> : null}
          <HomePopup {...pageProps} />
        </NewsDataProvider>
      </TranslationProvider>
    </VisibleProvider>
  );
}

export default MyApp;
